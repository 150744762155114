import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/colored/app-logo-sf.png';
import textLogo from '../../images/colored/text-sf.png';
import adminIcon from '../../images/icon-admin.svg';
import operativeProjectsIcon from '../../images/icon-operative-projects.svg';
import strategicProjectsIcon from '../../images/icon-strategic-projects.svg';
import classes from './SideMenu.module.scss';
import Select from '../../components/Select';
import businessUnits from '../../pages/businessUnits';

const MenuItem = ({ path, iconKey, name, opened, onClick, url }) => (
  <div
    role="button"
    tabIndex={0}
    className={`${classes.menuItem} ${
      window.location.pathname.includes(path) ? classes.activeMenuItem : ''
    }`}
    onClick={onClick}
  >
    <Link to={path} className={classes.iconLink}>
      {url ? (
        <img title={name} alt={name} className={classes.svg} src={url} />
      ) : (
        <i title={name} className={`la ${iconKey} ${classes.innerIcon} `} />
      )}
    </Link>
    {opened ? (
      <div className={classes.menuItemTitle}>
        <Link to={path} className={classes.openLink}>
          {name}
        </Link>
      </div>
    ) : null}
  </div>
);

const InnerMenu = ({ opened, logout, isHyperAdmin, isAdmin, strategicAccess, operativeAccess }) => (
  <div className={`${classes.innerMenuRoot} ${!opened ? classes.innerMenuRootClosed : ''}`}>
    <div className={classes.innerMenuContainer}>
      <MenuItem path="/dashboard" name="Dashboard" iconKey="la-bar-chart" opened={opened} />
      {operativeAccess ? (
        <MenuItem
          path="/operative-projects"
          name="Operative Projects"
          url={operativeProjectsIcon}
          opened={opened}
        />
      ) : null}
      {strategicAccess ? (
        <MenuItem
          path="/strategic-projects"
          name="Strategic Projects"
          url={strategicProjectsIcon}
          opened={opened}
        />
      ) : null}
      {strategicAccess ? (
        <MenuItem path="/portfolios" name="Portfolios" iconKey="la-clipboard" opened={opened} />
      ) : null}
    </div>
    <div className={classes.innerMenuContainer}>
      {isHyperAdmin ? (
        <MenuItem path="/hyperadmin" name="HyperAdmin" opened={opened} iconKey="la-globe" />
      ) : null}
      {isAdmin ? <MenuItem path="/admin" name="Admin" url={adminIcon} opened={opened} /> : null}
      <MenuItem path="/faq" name="FAQ" iconKey="la-info-circle" opened={opened} />
      <MenuItem path="/settings" name="Settings" iconKey="la-gear" opened={opened} />

      <MenuItem
        path="/login"
        name="Logout"
        iconKey="la-sign-out"
        opened={opened}
        onClick={logout}
      />
    </div>
  </div>
);
export default class SideMenu extends Component {
  state = {
    opened: false,
  };

  _onClick = () => {
    this.setState(prevState => ({ opened: !prevState.opened }));
  };

  componentDidMount = () => {
    const { setBusinessUnits, switchBusinessUnit } = this.props;
    setBusinessUnits();
  };

  handleBusinessUnitSwitch = el => {
    const { switchBusinessUnit, currentBusinessUnit } = this.props;
    switchBusinessUnit(el.value);
  };

  render() {
    const {
      strategicAccess,
      operativeAccess,
      loggedIn,
      logout,
      isAdmin,
      isHyperAdmin,
      businessUnits,
      currentBusinessUnit,
    } = this.props;
    const current = { label: currentBusinessUnit.name, value: currentBusinessUnit.id };
    const { opened } = this.state;
    return (
      <div className={`${classes.root} ${opened ? classes.openRoot : ''}`}>
        <div className={classes.container}>
          <div style={{ display: 'flex' }}>
            <img alt="Logo" src={logo} className={classes.logo} />
            <img
              alt="Logo"
              src={textLogo}
              className={`${classes.textLogo} ${opened ? classes.textLogoOpen : ''}`}
            />
          </div>

          <div style={{ display: 'flex' }}>
            <i
              role="button"
              tabIndex={-1}
              className={`la ${opened ? 'la-close' : 'la-bars'} ${classes.iconButton}`}
              onClick={this._onClick}
            />
            {opened ? (
              <span
                role="button"
                tabIndex={-3}
                onClick={this._onClick}
                className={classes.closeTextButton}
              >
                Close
              </span>
            ) : null}
          </div>
        </div>
        <div className={classes.select}>
          {opened ? (
            <Select
              value={current}
              options={businessUnits}
              onChange={this.handleBusinessUnitSwitch}
              noBackground
            />
          ) : (
            <p className={classes.current}>{current.label}</p>
          )}
        </div>
        <InnerMenu
          mobile
          strategicAccess={strategicAccess}
          operativeAccess={operativeAccess}
          opened={opened}
          loggedIn={loggedIn}
          logout={logout}
          isAdmin={isAdmin}
          isHyperAdmin={isHyperAdmin}
        />
      </div>
    );
  }
}
